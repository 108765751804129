import React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

function SEO({ location, description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            social {
              twitter
            }
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "charSet",
          content: "UTF-8",
        },
        {
          name: "keywords",
          content: "kutya, segítőkutya, terápiáskutya, rohamjelzőkutya, mozgássérültet, segítő, kutya, AURA, VAURA, Alapítvány",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: "hu_HU",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${location.pathname}`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`,
        },
        {
          property: `og:site_name`,
          content: `${site.siteMetadata.title}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: `@${site.siteMetadata.social.twitter}`,
        },
        {
          name: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context" : "https://schema.org",
          "@graph" : [
            {
              "@type" : "Organization",
              "@id" : "http://vaura.hu/#organization",
              "url" : "${site.siteMetadata.siteUrl}",
              "name" : "${site.siteMetadata.title}",
              "logo" : {
                "@type" : "ImageObject",
                "@id" : "http://vaura.hu/#logo",
                "inLanguage" : "hu",
                "url" : "${site.siteMetadata.siteUrl}${site.siteMetadata.image}",
                "width" : 714,
                "height" : 714,
                "caption" : "${site.siteMetadata.title}"
              },
              "image" : {
                "@id" : "http://vaura.hu/#logo"
              }
            },
            {
              "@type" : "WebSite",
              "@id" : "http://vaura.hu/#website",
              "url" : "${site.siteMetadata.siteUrl}",
              "name" : "${site.siteMetadata.title}",
              "description" : "${site.siteMetadata.description}",
              "inLanguage" : "hu",
              "publisher" : {
                "@id" : "http://vaura.hu/#organization"
              }
            },
            {
              "@type" : "WebPage",
              "@id" : "${site.siteMetadata.siteUrl}${location.pathname}#webpage",
              "url" : "${site.siteMetadata.siteUrl}${location.pathname}",
              "name" : "${title}",
              "description" : "${metaDescription}",
              "inLanguage" : "hu",
              "isPartOf" : {
                "@id" : "http://vaura.hu/#website"
              },
              "datePublished" : "2021-02-02T23:45:35+00:00",
              "dateModified" :  "2021-02-02T23:47:36+00:00",
              "potentialAction" : [
                {
                  "@type" : "ReadAction",
                  "target" : [
                    "${site.siteMetadata.siteUrl}${location.pathname}"
                  ]
                }
              ]
            }
          ]
        }
        `}
      </script>
      <link rel="preconnect dns-prefetch" href="//www.google.com"/>
      <link rel="preconnect dns-prefetch" href="//fonts.googleapis.com"/>
      <link rel="preconnect dns-prefetch" href="//cse.google.com"/>
      <link rel="canonical" href={`${site.siteMetadata.siteUrl}${location.pathname}`}/>
      <script async src="https://cse.google.com/cse.js?cx=015378035370168471795:t5mnnuhuylt"/>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `hu`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
